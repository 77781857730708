.wrapper
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    background: #F8F8F8

    >div
        margin: 75px auto
        background: #FFF
        border-radius: 10px
        padding: 50px 47px 36px
        display: flex
        flex-direction: column
        >svg
            margin-bottom: 20px
    .title
        font-size: 32px
        font-weight: 700
        line-height: 44px
        letter-spacing: 0em
        text-align: left
        margin: 0
        width: 100%
    .fields
        display: flex
        flex-direction: column
        padding-top: 20px
        width: 300px
        >svg
            position: absolute
            right: 13px
        &:hover
            cursor: pointer
        >div>div
            // margin-bottom: 14px
    button
        background: #FF7BAC
        margin-bottom: 40px
        width: 300px
        font-size: 16px
        font-weight: 400
        line-height: 19px
        letter-spacing: 0em
        text-align: left
        height: 50px
        color: #fff
        font-family: "Zeitung Micro Pro"
        border-radius: 5px
        border: none
        &:disabled
            background: #FF7BAC
            opacity: 0.5
            color: #fff
        &:hover
            background: #FF7BAC
            cursor: pointer

    .links
        display: flex
        justify-content: space-between
        margin-bottom: 15px
        font-size: 12px
        width: 300px
        >a
            text-decoration: none
            color: #777777
            &:hover
                color: #3376A3
.header
    position: fixed
    top: 14px
    left: 14px
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 700
    line-height: 21px
    letter-spacing: 0em
    text-align: left
    color: #000
    margin: 0 !important
    display: flex
    align-items: center
    align-content: center
    svg
        margin-right: 10px
