// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.wrapper
    display: flex
    align-content: flex-start
    flex-wrap: wrap
    // width: 100%
    // height: calc( 100vh - 0px )
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    padding: 0px 15px 10px
    // margin: 40px 0px 0px

.wrapper::-webkit-scrollbar
    width: 0
    height: 0
.sort
    display: flex
    align-items: center
    position: relative
    &__filter
        display: flex
        margin: 0 20px 0 5px
        color: #000
        align-items: center
        &:hover
            cursor: pointer
        svg
            margin-left: 5px

.sortMenu
    width: 122px
    background: #1F1F1F
    color: #fff
    position: absolute
    top: 30px
    left: 0
    border-radius: 5px
    >div
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left

        // height: 40px
        padding: 11px 10px
        &:hover
            background: #292929
            border-radius: 5px
            cursor: pointer
.flex
    display: flex
    width: 100%
    justify-content: space-between
    display: flex
    justify-content: space-between
    color: rgba(0, 0, 0, 0.5)
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    padding: 30px 15px 45px
.items
    display: flex
    flex-wrap: wrap
    >div
        display: flex
        flex-direction: column
        margin: 0 14px 15px
        &:hover
            cursor: pointer
