.tags
    display: flex
    justify-content: space-between
    >button
        background: red
        width: 30px
        height: 30px
        background: #3376A3
        margin-left: 5px
        border-radius: 5px
        display: flex
        justify-content: center
        align-items: center
        border: none
        &:hover
            cursor: pointer
        &:disabled
            opacity: 0.3
            &:hover
                cursor: not-allowed
    >input
        background: #F8F8F8
        border: 1px solid rgba(0, 0, 0, 0.1)
        border-radius: 5px
        height: 26px
        width: 100%
    &__items
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: center
        // width: 210px
        flex-wrap: wrap
        // border: 1px solid #3376A3
        display: flex
        margin-top: 10px
        div
            color: #3376A3 !important
        >div
            border: 1px solid #3376A3
            display: flex
            align-items: center
            padding: 3px 9px
            margin-right: 5px
            margin-bottom: 5px
            border-radius: 134px

            svg
                margin-left: 5px
