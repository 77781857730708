// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  

.flexRow
    display: flex
    height: calc( 100vh - 121px )
    justify-content: space-between
.flexCenter
    display: flex
    align-items: center
.wrapper
    background: #F8F8F8
    height: 100%
    max-height: calc( 100vh - 50px )
    overflow-y: scroll
    color: #000
    input
        &:focus
            border: 2px solid #3376A3

.wrapper::-webkit-scrollbar
    display: none
.wrapper
    -ms-overflow-style: none
    scrollbar-width: none
.header
    display: flex
    // justify-content: space-between
    height: 70px
    align-items: center
    padding: 0 10px
    border-bottom: 1px solid rgb(51, 118, 163,30%)
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 600
    line-height: 21px
    letter-spacing: 0em
    text-align: left

.form
    height: calc( 100vh - 121px )
    button
        height: 30px
        width: 63px
        border: 1px solid rgba(0, 0, 0, 0.2)
        background: #3376A3
        border-radius: 8px
        color: #fff

        // margin: 30px 0px
        &:disabled
            opacity: 0.5
.leftSide
    background: #fff
    width: 460px
    margin: 20px auto 20px
    border-radius: 10px
    overflow: hidden
    height: 670px
    >img
        width: 100%
        height: 285px
    &__logo
        display: flex
        margin: 15px 0
        padding: 0 20px
        align-items: center
        &__rightSideWrapper
            display: flex
            flex-direction: column
            max-width: 200px
            justify-content: space-between
            button
                background: transparent
                color: #3376A3
                border: 1px solid #3376A3
                &:hover
                    cursor: pointer

        &__img
            height: 80px
            width: 90px
            border: 1px dashed #000
            border-radius: 10px
            margin-right: 15px
            >img
                height: 100%
                width: 100%
                object-fit: contain

.title

    font-family: 'Zeitung Micro Pro'
    font-style: normal
    font-weight: 700
    font-size: 14px
    line-height: 21px
.description
    margin: 5px 0
    // font: Inter
    font-weight: 400
    font-size: 12px
    color: rgba(0, 0, 0, 0.5)
.inputControll
    border-radius: 6px
    border: 1px solid #000
    width: 100%
.DropZone
    width: 100%
    height: 100px
    border: 1px dashed #000
    border-radius: 6px
    display: flex
    align-items: center
    justify-content: center

.rightSide
    border-left: 1px solid rgb(51, 118, 163,30%)
    padding: 11px 20px
    &__scene
        display: none
        width: 610px
        // height: 371px
        background: #000
        padding: 15px
        &__header
            align-items: center
            display: flex
            margin-bottom: 12px
            img
                height: 50px
                width: 50px
                margin-right: 15px
            h1, span
                color: #fff
                margin: 0
        &__media
            display: flex
            height: 228px
            align-items: center
            justify-content: space-between
        &__item
            width: 85%
            display: flex
            background: #D7E7FF
            height: 100%
            align-items: center
            justify-content: center
        &__arrow
            // width: 45px
        &__description
            width: 80%
            margin-top: 10px
            margin-bottom: 16px
            color: #fff
            opacity: 0.5
            padding: 0 45px
    >span
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left

.padding
    padding: 0 20px
.createBtn
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: center
    height: 44px !important
    width: 100px !important
