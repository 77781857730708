
.wrapper
    width: 100vw
    display: flex
    height: 100vh
    position: fixed
    top: 0
    left: 0
    align-items: center
    justify-content: center
    z-index: 1500
    background: rgb(0,0,0,0.8)

.itemVideo
    display: flex
    justify-content: center
    // height: calc(100vh - 162px)
    white-space: nowrap
    align-items: center
    >div
        >div
            height: calc(100vh - 351px) !important // player styles
            width: auto !important
    >div
        &:nth-child(1)
            position: relative //wrapper for buttons inside video element
.btnGroup
    border-radius: 5px
    position: absolute
    right: 13px
    top: 15px
    background: rgba(0, 0, 0, 0.50)
    display: flex
    align-items: center
    padding: 5px
    >svg
        &:hover
            cursor: pointer
.itemImage
    display: flex
    justify-content: center
    
    white-space: nowrap
    >div
        position: relative
    img
        height: calc(100vh - 162px)
.fullScreen
    display: flex
    opacity: 0.5
    margin-left: 8px
    &:hover
        cursor: pointer
