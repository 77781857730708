// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.wrapper

.header
    display: flex
    align-items: center
    justify-content: space-between
    padding: 13px 13px 12px 20px
    background-color: transparent

    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    &__label
        opacity: 0.5
    input
        height: 26px
        background: #F8F8F8
        border-radius: 5px
        border: 1px solid rgba(0, 0, 0, 0.1)
    >div
        display: flex
        align-items: center
        >svg
            margin-right: 7px
    button
        height: 44px
        width: 97px
        left: 0px
        top: 0px
        border-radius: 10px
        padding: 10px 20px 10px 20px
        background: #3376A3
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: left
        &:hover
            background: #3376A3

.project
    &__container
        background: transparent
        height: 100%
        flex-grow: 1
        color: #fff
        padding: 16px 20px
        button
            display: flex
            // display: none
    &__countProjects
        color: #9f7f7f
        margin-left: 10px
    &__bar
        display: flex
        justify-content: space-between
    &__view
        background-color: transparent
        display: flex
        margin: 0px 10px
        &__active
            background-color: #3C3C3C
            padding: 7px
            display: flex
            border-radius: 5px

.flexCenter
    display: flex
    align-items: center
.projWrapper
    display: flex
    flex-wrap: wrap
    max-height: calc( 100vh - 175px )
    overflow-y: scroll
    align-items: flex-start
    padding-top: 46px
    >a
        margin: 15px 10px
        text-decoration: none
.projWrapperDeck
    display: flex
    flex-wrap: wrap
    max-height: calc( 100vh - 172px )
    overflow-y: scroll
    >a
        margin: 15px 10px
        text-decoration: none
    button
        display: flex
        flex-direction: column
        margin: 0
.projWrapper::-webkit-scrollbar
    width: 0
    height: 0
.projWrapperDeck::-webkit-scrollbar
    width: 0
    height: 0
.flexRow
    display: flex
.comment
    display: flex
    justify-content: space-between
    width: 100%
    margin-top: 10px
    div
        display: flex
        align-content: center
.deckHeader
    display: flex
    align-items: center
    text-decoration: none
    color: #000
    opacity: 0.5
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    >svg
        margin-right: 8px
.name
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 600
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    color: rgba(0, 0, 0, 1)
.subName
    font-family: Zeitung Micro Pro
    font-size: 10px
    font-weight: 400
    line-height: 15px
    letter-spacing: 0em
    text-align: left
    color: rgba(0, 0, 0, 0.5)
    &:first-child
        margin-right: 10px
.projectItem
    margin-right: 20px
    margin-bottom: 30px
    // img
    //     height: 140px
    //     width: 100%
    //     border-radius: 0px 0px 3px 3px

.editTime
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    >div
        display: flex
        justify-content: space-between

.CardContent
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 5px 10px
    padding-bottom: 12px

.thumb
    height: 140px
    width: 100%
    border-radius: 8px 8px 3px 3px
    object-fit: cover
    &__selected
        height: 140px
        width: 100%
        border-radius: 8px 8px 0px 0px
        object-fit: cover
.sort
    display: flex
    align-items: center
    position: relative
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    // margin-bottom: 46px

    color: rgb(0,0 ,0, 0.5)

    &__filter
        display: flex
        margin: 0 20px 0 5px
        color: #000
        align-items: center
        color: #000
        &:hover
            cursor: pointer
        svg
            margin-left: 5px
            &:hover
                cursor: pointer
.row
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    >div
        &:first-child
            width: 100%
.members
    display: flex
    position: relative
    height: 20px
    &__wrap
        display: flex
        justify-content: space-between
        align-items: center
    >div
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 20px
        letter-spacing: 0em
        text-align: left
        position: absolute