.header
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0px 20px
    height: 65px
    background-color: #F8F8F8
    // font-family: 'Inter'
    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 19px
    color: #000
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    a
        display: flex
        align-items: center
        text-decoration: none
        color: rgba(0, 0, 0, 0.5)
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        // opacity: 0.5
        // background-color: rgba(0, 0, 0, 0.5)

    button>a
        opacity: 1
.flexCenter
    display: flex
    align-items: center
.deckHeader
    display: flex
    align-items: center
    text-decoration: none
    color: #000
    opacity: 0.5
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    >svg
        margin-right: 8px
.buttonGroup
    display: flex

.buttonGroup
    >div
        display: flex
        align-items: center
        margin-left: 10px
    >button
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: center
        padding: 0 5
        margin: 0 5px
        background: transparent
        box-shadow: none
        color: #000
        border: 1px solid gray
        border-radius: 10px
        padding: 8px 10px
        &:hover
            cursor: pointer
        >a
            font-family: Zeitung Micro Pro
            font-size: 16px
            font-weight: 400
            line-height: 24px
            letter-spacing: 0em
            color: #000
.addFiles
    width: 210px
    background: #1F1F1F
    color: #fff
    position: absolute
    top: 113px
    right: 16px
    border-radius: 5px

    &__wrap
        height: 100vh
        width: 100vw
        position: fixed
        top: 0
        left: 0
        z-index: 5555

    >div
        display: flex
        justify-content: space-between
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        padding: 10px 11px
        opacity: 0.6
        &:hover
            cursor: pointer
