@import '../../assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'

.wrapper
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    margin-left: 15px
    // height: calc( 100vh - 145px )
    height: 100%

.wrapper::-webkit-scrollbar
    display: none
.btnGroup
    border-radius: 5px
    position: absolute
    right: 13px
    top: 15px
    background: rgba(0, 0, 0, 0.50)
    display: flex
    align-items: center
    padding: 5px
    @media screen and (max-width: 428px)
        right: 13px
        top: 60px
    >svg
        &:hover
            cursor: pointer
.relative
    position: relative
    border: 3px solid rgba(0,0,0,0)
.selected
    border-radius: 5px
    border: 3px solid #0094FF
    position: relative

.relative .btnGroup
    visibility: hidden

.relative:hover .btnGroup
    visibility: visible
.selected .btnGroup
    visibility: hidden

.selected:hover .btnGroup
    visibility: visible
