.media
    object-fit: contain
.header
    display: flex
    align-content: center
    justify-content: space-between
    padding: 5px 10px 0 30px
    align-items: center
    @media screen and (max-width: 428px)
        padding: 30px 10px 0

    >div
        display: flex
        // align-items: center
    img
        width: 100px
        height: 100px
        object-fit: contain
    &__title
        font-size: 20px
        font-weight: 400
        line-height: 20px
        letter-spacing: 0em
        text-align: left
        display: flex
        flex-direction: column

.wrapper
    width: 100%
    display: flex
    flex-direction: column
    height: 100%
    // marrgin-bottom: 20px
    justify-content: space-between

.carousel
    position: relative

.itemMini
    margin: 0 6px
    img
        height: 90px
        border-radius: 5px
        object-fit: cover
.index
    position: absolute
    top: 0.1rem
    font-size: 0.7rem
    font-weight: bold
    font-family: Roboto
    color: black
    padding: 0.1rem 0.3rem
    background-color: lightgray
    left: 1rem
    opacity: 0.75

.captionContainer
    position: absolute
    bottom: 0

.caption
    padding: 0.3rem 0.5rem
    font-size: 0.8rem
    font-weight: bold
    font-family: Roboto
    background-color: lightgray
    color: black
    margin: 0 auto
    opacity: 0.75

.btnNext
    position: absolute
    font-size: 50px
    color: lightgray
    cursor: pointer
    // top: 10px
    // right: 10px
    left: calc( 100vw - 340px )
    z-index: 15000000
.btnPrev
    position: absolute
    font-size: 50px
    color: lightgray
    cursor: pointer
    right: 0px
    z-index: 15000000
.footer
    position: relative
    font-family: Zeitung Micro Pro
    font-size: 10px
    font-weight: 700
    line-height: 15px
    letter-spacing: 0em
    text-align: left
    display: flex
    justify-content: space-between
    margin-top: 15px
    padding: 0 20px 15px
    @media screen and (max-width: 428px)
        justify-content: center
    &__rightSide
        display: flex
        align-items: center
        border-radius: 27px
        background: #1C1C1C
        padding: 7px 8px
        >div

            border-radius: 27px
        svg
            margin-left: 5px
    &__links
        svg
            margin-right: 24px
.secondType
    width: 100%
    display: flex
    height: 100%
    marrgin-bottom: 20px
    &__leftSide
        width: 300px
        padding: 36px 0 15px 20px
        display: flex
        flex-direction: column
        justify-content: space-between
        font-family: Arial Black
        font-size: 24px
        font-weight: 900
        line-height: 24px
        letter-spacing: 0em
        text-align: left
    &__container
        display: flex
        flex-direction: column
        width: calc( 100vw - 300px )

    &__directories
        display: flex
        flex-direction: column
        margin-top: 25px
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 700
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        &:hover
            cursor: pointer
        &__selected
            text-decoration: underline
.btnNextFirstType
    position: absolute
    font-size: 50px
    color: lightgray
    cursor: pointer
    top: 364px
    right: 28px
.btnPrevFirstType
    position: absolute
    font-size: 50px
    color: lightgray
    cursor: pointer
    top: 364px
    left: 28px

.downItems::-webkit-scrollbar
    display: none
.downItems
    display: flex
    align-content: center
    overflow-x: scroll
    // padding-left: 50px
    -ms-overflow-style: none
    scrollbar-width: none
    @media screen and (max-width: 428px)
        padding-left: 10px
    &__item
        margin: 0 6px
        img
            width: 120px
            height: 72px
            border-radius: 5px
            object-fit: cover
            opacity: 0.5
    &__selectedItem
        margin: 0 6px
        img
            width: 120px
            height: 72px
            border-radius: 5px
            object-fit: cover
            border: 1px solid #FFF
.fileName
    display: flex
    justify-content: center
    // font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 700
    line-height: 18px
    letter-spacing: 0em
    text-align: center
    margin-bottom: 15px
    margin-top: 15px

.itemVideo
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    display: flex
    justify-content: center
    // height: calc(100vh - 455px)
    white-space: nowrap
    align-items: center
    canvas
        position: absolute
        width: 100%
        height: 100%
        transform: scale(1.2)
        -webkit-filter: blur(100px)
        opacity: 0.5
        z-index: -1
        left: 0
    >div
        >div
            // height: calc(100vh - 455px) !important // player styles
            // width: auto !important
    >div
        &:nth-child(1)
            position: relative //wrapper for buttons inside video element
            height: 100%
            // width: 100%
.btnGroup
    border-radius: 5px
    position: absolute
    right: 13px
    top: 15px
    background: rgba(0, 0, 0, 0.50)
    display: flex
    align-items: center
    padding: 5px
    @media screen and (max-width: 428px)
        right: 13px
        top: 60px
    >svg
        &:hover
            cursor: pointer
.itemImage
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    display: flex
    justify-content: center
    // height: calc(100vh - 455px)
    white-space: nowrap
    canvas
        position: absolute
        width: 100%
        height: 100%
        transform: scale(1.2)
        -webkit-filter: blur(100px)
        opacity: 0.5
        z-index: -1
        left: 0
    >div
        position: relative
        height: 100%
        // width: 100%
    >div>img
        height: 100%
        width: 100%
.fullScreen
    display: flex
    opacity: 0.5
    margin-left: 8px
    &:hover
        cursor: pointer
.currentItem
    position: relative
    flex-grow: 1
