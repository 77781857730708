// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.wrapper

.container
    display: flex
    height: calc( 100vh - 116px )
    justify-content: space-between
    width: 100%

// .header
//     display: flex
//     align-items: center
//     justify-content: space-between
//     padding: 0px 20px
//     background-color: #F8F8F8
//     font-family: 'Inter'
//     font-style: normal
//     font-weight: 700
//     font-size: 16px
//     line-height: 19px
//     color: #000
//     height: 78px
//     border-bottom: 1px solid rgba(0, 0, 0, 0.1)
//     a
//         text-decoration: none
//         color: #000

// .back
//     display: flex
//     font-family: Zeitung Micro Pro
//     font-size: 14px
//     font-weight: 600

//     >svg
//         margin-right: 19px

// .buttonGroup
//     display: flex
//     align-items: center
//     button,span
//         font-weight: 400
//         font-family: Zeitung Micro Pro
//         font-size: 12px
//     >svg
//         margin: 0 15px 0 7px
//     >button
//         color: #000
//         box-shadow: none
//         padding: 0 5
//         background: transparent
//         margin: 0 5px
//         border: 1px solid gray
//         border-radius: 15px
//         font-size: 16px
//         line-height: 24px
//         letter-spacing: 0em
//         text-align: left
//         text-transform: none
//         padding: 9px 15px
//         &:last-child
//             background: #3376A3
//             border: 1px solid rgba(0, 0, 0, 0.1)
//             color: #fff

// .deck
//     height: calc( 100vh - 158px )
//     background: #F8F8F8
//     // height: 100%
//     flex-grow: 1
//     color: #fff
//     padding: 30px 20px 0
//     // max-height: calc( 100vh - 225px )
//     // overflow-y: scroll
//     width: 0

//     &__scene
//         background: #000
//         // width: 100%
//         // height: 98%
//         border-radius: 10px
//         padding: 10px
//     &__header
//         align-items: center
//         display: flex
//         margin-bottom: 12px
//         img
//             height: 50px
//             width: 50px
//             margin-right: 15px
//         h1, span
//             color: #fff
//             margin: 0
//     &__footer
//         width: 40%
//         font-family: 'Zeitung Micro Pro'
//         font-style: normal
//         font-weight: 400
//         font-size: 12px
//         line-height: 18px
//     &__type2
//         display: flex
//         background: black
//         // width: 150px
//         // height: 98%
//         &__deck_wrap
//             width: calc(100% - 220px)
//         &__menu
//             max-width: 200px
//             padding: 0 10px 0
//             border: 1px solid rgba(255, 255, 255, 0.1)
//             >div
//                 display: flex
//                 flex-direction: column
//                 margin-top: 15px
//             >div
//                 &:last-child
//                     margin-top: 50px

//             span
//                 font-family: Arial Black
//                 font-size: 24px
//                 font-weight: 900
//                 line-height: 24px
//                 letter-spacing: 0em
//                 text-align: left
//                 white-space: wrap
//         &__header
//             display: flex
//             justify-content: space-between
//             padding: 5px 10px
//             div
//                 display: flex
//                 align-items: center
//                 svg
//                     &:hover
//                         cursor: pointer
//             img
//                 height: 100px
//                 width: 100px
//             button
//                 border: 1px solid rgba(255, 255, 255, 0.5)
//                 background: transparent
//                 color: rgba(255, 255, 255, 0.5)
//                 font-family: Arial
//                 font-size: 12px
//                 font-weight: 700
//                 line-height: 12px
//                 letter-spacing: 0em
//                 text-align: left
//                 border-radius: 10px
//                 padding: 6px 14px
//                 margin-left: 40px
