body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Zeitung Micro Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiButton-root{
  text-transform: none !important;
}
@font-face {
  font-family: "Archivo Black";
  src: url("./fonts/ArchivoBlack-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Zeitung Micro Pro";
  src: url("./fonts/zeitung-pro.otf") format("opentype");
}
.MuiTreeItem-group{
  margin-left: 0 !important;
  /* padding-left: 17px !important; */
}
.noShowColapseIcon .MuiTreeItem-iconContainer {
  display: none !important;
 
}
.carusel{
  width: 100%;
  display: flex;
  height: 100%;
  marrgin-bottom: 20px;

}
.carusel .alice-carousel:last-child  .alice-carousel__stage li  {
  width: 165px 
}   

.type1FirstCarusel{
  position: relative;
}
.type1FirstCarusel .alice-carousel{
  width: calc(100vw - 480px);
  margin-top: 37px;
  margin-bottom: 90px;
}

.type2FirstCarusel{
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.type1{
  margin-left: 50px;
}
.type1 .alice-carousel .alice-carousel__stage li {
  width: 183px;
}
.hide-resize-observer {
  display: none !important;
}
.ShareDateExpaire{
  position: relative;
  display: flex;
  flex-direction: column;
  /* font-family: "Robotto"; */
  font-size: 14px;
  font-weight: 400;
}
.ShareDateExpaire .react-datepicker__input-container input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 24px;
  border-radius: 5px;
  margin-bottom: 15px;

}
.ShareDateExpaire .react-datepicker-popper {
  position: fixed !important ;
  inset: unset !important;
  transform: none !important;
}
/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}
.ElementsApp input{
  
  font-family: "Zeitung Micro Pro";
}