// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.wrapper
    display: flex
    flex-direction: column
    width: 100%

.project
    background: #F8F8F8
    flex-grow: 1
    color: #fff
    padding: 30px 20px 0
    // max-height: calc( 100vh - 225px )
        // height: 100%
    overflow-y: scroll

    &__countProjects
        color: #9f7f7f
        margin-left: 10px
    &__bar
        display: flex
        justify-content: space-between
        color: rgba(0, 0, 0, 0.5)
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        // >div
        //     display: flex

    &__view
        background-color: transparent
        display: flex
        margin: 0px 10px
        &:hover
            cursor: pointer
        &__active
            background-color: #F0F0F0
            padding: 7px
            display: flex
            border-radius: 5px

.flex
    display: flex
    align-items: center
.projWrapper
    display: flex
    flex-wrap: wrap
    >a
        margin: 15px 10px
        text-decoration: none

.project::-webkit-scrollbar
    width: 0
    height: 0

.DropZone

    &__wrapper
        padding-top: 15px
        background-image: url(/assets/img/DragAndDrop.svg)
        background-position: center
        background-repeat: no-repeat
        // background-size: contain
        width: 100%
        min-height: 90%
        display: flex
        flex-wrap: wrap
        // background: #F8F8F8
        align-content: flex-start
        >div
            margin: 15px 10px
            text-decoration: none
            display: flex
            flex-direction: column
            // height: 100%
            border-radius: 10px
            background: transparent
    &__wrapper__empty
        padding: 20px
        background-image: url(/assets/img/DragAndDrop.svg)
        background-position: center
        background-repeat: no-repeat
        // background-size: contain
        width: 100%
        min-height: 90%
        display: flex
        flex-wrap: wrap
        // background: #F8F8F8
        align-content: flex-start

.emptyDropzone
    // height: 150px
    width: 100%
    background: #D7E7FF
    border: 2px dashed #3376A3
    display: flex
    align-items: center
    justify-content: center
    >div
        display: flex
        flex-direction: column
    span

        font-size: 12px
        font-weight: 400
        line-height: 15px
        letter-spacing: 0em
        text-align: center
        color: #000

.container
    display: flex
    height: calc( 100vh - 116px )
.flexCenter
    display: flex
    align-items: center
.sort
    display: flex
    align-items: center
    position: relative
    &__filter
        display: flex
        margin: 0 20px 0 5px
        color: #000
        align-items: center
        &:hover
            cursor: pointer
        svg
            margin-left: 5px

.sortMenu
    width: 122px
    background: #1F1F1F
    color: #fff
    position: absolute
    top: 30px
    left: 0
    border-radius: 5px
    >div
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left

        // height: 40px
        padding: 11px 10px
        &:hover
            background: #292929
            border-radius: 5px
            cursor: pointer

.list
    display: flex
    flex-direction: column
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 700
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    width: 100%
    margin: 0
    margin: 15px 0px !important
    svg
        cursor: pointer
    >div
        display: flex
        align-items: center
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 700
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        color: #000
        justify-content: space-between
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        min-height: 50px
        span
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left
            color: rgba(0, 0, 0, 0.5)
            // flex-grow: 2
            width: 20%

        img
            width: 40px
            height: 30px
            margin-right: 9px
            border-radius: 5px
            object-fit: contain

    &__name
        display: flex
        align-items: center
        width: 30%
    &__like
        display: flex
        align-items: center
    &__svg
        width: 80px
        display: flex
        align-items: center
        justify-content: space-between
