// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  

.flexRow
    display: flex
.flexCenter
    display: flex
    align-items: center
.wrapper
    background: #F8F8F8
    height: 100%
    max-height: calc( 100vh - 50px )
    overflow-y: scroll
    color: #000
.wrapper::-webkit-scrollbar
    display: none
.wrapper
    -ms-overflow-style: none
    scrollbar-width: none
.header
    display: flex
    justify-content: space-between
    height: 70px
    align-items: center
    padding: 0 10px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    align-content: center
    >button
        border-radius: 10px
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        width: 76px
        height: 44px
        background: #3376A3
        border: 1px solid rgba(0, 0, 0, 0.1)
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: left

        &:hover
            background: #3376A3
            cursor: pointer

.logo
    display: flex
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    align-items: center
    color: rgb(0,0 ,0, 0.5 )
    svg
        margin-right: 8px
// .banner
//     margin: 10px 15px
//     border: 2px solid gray
//     background: orange
//     // width: 90%
//     // height: 150px
//     padding: 15px 10px
//     &__header
//         display: flex
//         align-items: center
//         >svg
//             margin-right: 5px
//     &__templates
//         &__wrapper
//             margin: 10px 0
//             //border: 1px solid red
//             display: flex
//         &__item
//             width: 167px
//             height: 136px
//             border: 1px solid green
//             margin-right: 15px
//             display: flex
//             align-items: center
//             justify-content: center
.decks
    color: #000
    &__header
        display: flex
        justify-content: space-between
    &__filters
        display: flex
        justify-content: space-between
        margin-bottom: 36px
    &__container
        background: transparent
        height: 100%
        flex-grow: 1
        padding: 30px 20px
    &__item
        width: 307px
        background-color: #F8F8F8
        border-radius: 10px
        border: 1px solid rgba(0, 0, 0, 0.2)
        img
            height: 125px
            width: 100%
            object-fit: contain
            border-top-left-radius: 10px
            border-top-right-radius: 10px
        span
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 600
            line-height: 18px
            letter-spacing: 0em
            text-align: lefts
        >div>div
            padding: 10px 10px 16px 10px
        &:hover
            cursor: pointer

.projWrapper
    display: flex
    flex-wrap: wrap
    >div
        margin: 0 10px 35px 0

        text-decoration: none
.sort
    display: flex
    align-items: center
    position: relative
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400

    color: rgb(0,0 ,0, 0.5)

    &__filter
        display: flex
        margin: 0 20px 0 5px
        color: #000
        align-items: center
        color: #000
        svg
            margin-left: 5px
            &:hover
                cursor: pointer
.sortMenu
    width: 122px
    background: #1F1F1F
    color: #fff
    position: absolute
    top: 30px
    left: 0
    border-radius: 5px
    >div
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left

        // height: 40px
        padding: 11px 10px
        &:hover
            background: #292929
            border-radius: 5px
            cursor: pointer
.flex
    display: flex
    align-items: center
