.list
    display: flex
    flex-direction: column
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 700
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    width: 100%
   
    svg
        cursor: pointer
    >div
        display: flex
        align-items: center
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 700
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        color: #000
        justify-content: space-between
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        min-height: 50px
        span
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left
            color: rgba(0, 0, 0, 0.5)
            // flex-grow: 2
            width: 20%

    img
        width: 40px
        height: 30px
        margin-right: 9px
        border-radius: 5px

    &__name
        display: flex
        align-items: center
        width: 30%
    &__like
        display: flex
        align-items: center
    &__svg
        width: 80px
        display: flex
        align-items: center
        justify-content: space-between
