// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  

.header
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0px 20px 
    height: 60px
    background-color: #F8F8F8
    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 19px
    color: #000
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    a
        text-decoration: none
        color: #000
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        opacity: 0.2
    button>a
        opacity: 1
.buttonGroup
    display: flex

.buttonGroup>button
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: center
    padding: 0 5
    font-weight: 400
    font-size: 12px
    margin: 0 5px
    background: transparent
    box-shadow: none
    color: #000
    border: 1px solid gray
    border-radius: 15px
    &:hover
        padding: 0 5
        font-weight: 400
        font-size: 12px
        margin: 0 5px
        background: transparent
        box-shadow: none
        color: #000
        border: 1px solid gray
        border-radius: 15px

.flexRow
    display: flex
    width: 100%
    height: 100%
    justify-content: space-between
    height: calc( 100vh - 120px )

.imageWrap
    padding: 10px
    width: 100%
    padding: 10px
    img
        width: 100%
        height: 100%
        object-fit: contain

.flexCenter
    display: flex
    align-items: center
.breadcrumbs
    li
        div
            text-decoration: none
            color: #000
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            opacity: 0,2

