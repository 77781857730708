// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.wrapper
    position: absolute
    background: #000
    color: #fff
    height: 100vh
    width: 100vw
    left: 0
    top: 0
    z-index: 1200
    overflow: hidden

// .container
//     display: flex
//     height: calc( 100vh - 60px )
// .btn
//     display: flex
//     flex-wrap: wrap
//     button
//         display: flex
//         flex-direction: column
// .project
//     background: #F8F8F8
//     flex-grow: 1
//     color: #fff
//     padding: 30px 20px 0
//     // max-height: calc( 100vh - 225px )
//     overflow-y: scroll
//     display: flex
//     flex-wrap: wrap
// .project::-webkit-scrollbar
//     width: 0
//     height: 0
// .buttonGroup
//     display: flexs
// .header

//     padding: 21px 20px 19px

// .flexCenter
//     display: flex
//     align-items: center
// .breadcrumbs
//     li
//         &:last-child>a
//             opacity: 1
