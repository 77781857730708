@import '../../assets/styles/_variables'
.wrapper
    display: flex
    height: 100vh
    width: auto
    background: #F8F8F8
    border-right: 1px solid rgba(0, 0, 0, 0.1)
.tabPanelWrapper
    min-width: 220px
    background: #fff
    color: #000
    height: 100vh
    // overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    button
        padding: 0 10px 0 0
        &:hover
            background: transparent
    &__header
        position: relative
        height: 50px
        background: #2C2C2C
        display: flex
        align-items: center
        padding: 0 16px 0 10px
        justify-content: space-between
        >span
            font-family: Zeitung Micro Pro
            font-size: 14px
            font-weight: 700
            line-height: 21px
            letter-spacing: 0em
            text-align: left
            color: rgba(255, 255, 255, 0.5)

.tabPanelWrapper::-webkit-scrollbar
    display: none

.asside
    padding: 16px 10px
    position: sticky
    top: 0
    background: transparent
    z-index: 10
    display: flex
    justify-content: space-between
    align-items: center
    >button
        background: transparent
        border-radius: 5px
        padding: 0 10px 0 0
        &:hover
            background: transparent

    &__title
        // color: #443939
        // font-family: 'Inter'
        font-style: normal
        font-weight: 400
        font-size: 16px
    &__label
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        margin-right: 15px
    &__link
        color: #000
        text-decoration: none
        display: flex
        height: 40px
        align-items: center
        width: 100%
        padding: 0 10px
        >svg
            margin-right: 10px
        &__wrapper
            padding-top: 15px
        &__active
            background: red
    &__search
        background: red
        width: 100px
        height: 10px
    &__tree
        &__wrapper
            padding: 10px
            overflow: scroll
            display: flex
            flex-direction: column
            font-size: 12px
            height: calc( 100vh - 125px )
            >a svg
                margin-right: 5px
        &__wrapper::-webkit-scrollbar
            display: none
        &__link
            text-decoration: none
            color: #000
            display: flex
            align-items: center
            font-weight: 600
            img
                width: 24px
                height: 16px
            svg
                margin-right: 6px
                &:hover
                    cursor: pointer
                // margin-left: 11px

            >li
                width: 100%
                list-style-type: none
                img
                    margin-right: 5px
                > div
                    height: 40px
                    display: flex
                    align-items: center
                    justify-content: space-between
                    padding: 0

                    >div,span
                        font-family: Zeitung Micro Pro
                        font-size: 12px
                        font-weight: 400
                        line-height: 18px
                        letter-spacing: 0em
                        text-align: left
                        img
                            margin-right: 5px
        &__file
            text-decoration: none
            color: #000
            display: flex
            align-items: center
            font-weight: 600
            background: #F7F7F7
            border-radius: 5px
            border: 2px solid rgba(255, 0, 0, .0)
            img
                width: 24px
                height: 16px
            svg
                margin-right: 6px
                &:hover
                    cursor: pointer

            >li
                width: 100%
                list-style-type: none
                img
                    margin-right: 5px
                > div
                    height: 40px
                    display: flex
                    align-items: center
                    justify-content: space-between
                    padding: 0

                    >div,span
                        font-family: Zeitung Micro Pro
                        font-size: 12px
                        font-weight: 400
                        line-height: 18px
                        letter-spacing: 0em
                        text-align: left
                        img
                            margin-right: 5px
        &__fileSelected
            text-decoration: none
            color: #000
            display: flex
            align-items: center
            font-weight: 600
            background: #rgb(215, 231, 255)
            border-radius: 5px
            border: 2px solid #3376A3
            img
                width: 24px
                height: 16px
            svg
                margin-right: 6px
                &:hover
                    cursor: pointer

            >li
                width: 100%
                list-style-type: none
                img
                    margin-right: 5px
                > div
                    height: 40px
                    display: flex
                    align-items: center
                    justify-content: space-between
                    padding: 0

                    >div,span
                        font-family: Zeitung Micro Pro
                        font-size: 12px
                        font-weight: 400
                        line-height: 18px
                        letter-spacing: 0em
                        text-align: left
                        img
                            margin-right: 5px

.spaceBetween
    display: flex
    justify-content: space-between
    // padding-left: 17px
.tab
    width: 20px
    height: 20px
    background-color: $white
    border-radius: 5px

.link
    padding: 14px 10px
    text-decoration: none
    color: #000
    display: flex
    align-items: center
    span
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
.treeViewFont
    white-space: nowrap
    li
        // padding-left: 17px

.menuLink
    text-decoration: none
    color: inherit
.logo
    display: flex
    align-items: center
    justify-content: center
    height: 50px
    background: #2C2C2C
.decks
    &__itemsWrap
        padding: 12px
        height: calc( 100vh - 110px )
        overflow: scroll
        -ms-overflow-style: none
        scrollbar-width: none
    &__itemsWrap::-webkit-scrollbar
        display: none

    &__label
        margin-bottom: 5px
        display: flex
        align-items: center
        >svg
            margin-right: 6px
            margin-left: 15px
        a
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            text-decoration: none
            color: #000

    &__card
        display: flex
        flex-direction: column
        border-radius: 15px
        height: 145px
        margin-bottom: 20px
        border: 1px solid gray
        overflow: hidden
        text-decoration: none
        color: #000
        font-family: 'Zeitung Micro Pro'
        font-style: normal
        font-weight: 400
        font-size: 12px
        border: 2px solid rgba(0, 0, 0, 0.1)

        // position: relative
        >img
            width: 200px
            height: 110px
            object-fit: contain
        &__footer
            display: flex
            justify-content: space-between
            align-items: center
            height: 100%
            // position: relative
            span
                margin-left: 10px
            svg
                margin-right: 10px
        &__selected
            display: flex
            flex-direction: column
            border-radius: 15px
            height: 145px
            margin-bottom: 20px
            border: 1px solid gray
            overflow: hidden
            text-decoration: none
            color: #000
            font-family: 'Zeitung Micro Pro'
            font-style: normal
            font-weight: 400
            font-size: 12px
            border: 2px solid #3376A3
            background: #D7E7FF
            >img
                width: 200px
                height: 110px
                object-fit: contain
            &__footer
                display: flex
                justify-content: space-between
                align-items: center
                height: 100%

                span
                    margin-left: 10px
                svg
                    margin-right: 10px
    &__pages
        padding: 0 12px
        div
            width: 194px
        img
            width: 100%
            height: 110px
.flex
    display: flex
    align-content: center
    padding-left: 17px
.settings

    &__tabs
        display: flex
        flex-direction: column
        padding: 0 10px
        a
            text-decoration: none
            color: #000
            display: flex
            align-items: center
        span
            font-family: 'Zeitung Micro Pro'
            font-style: normal
            font-weight: 400
            font-size: 12px
            margin-left: 5px

    &__item
        display: flex
        height: 40px
        border-radius: 5px
        padding-left: 10px
        &__selected
            background: #D7E7FF
            display: flex
            height: 40px
            border-radius: 5px
            padding-left: 10px

.pseudo
    position: absolute
    top: 0px
    width: 3px
    height: 50px
    left: 49px
    background: #2C2C2C
    pointer-events: none
.castings
    display: flex
    flex-direction: column
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 600
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    margin-top: 20px
    img
        height: 16px
        width: 16px
        margin-right: 8px
    a
        height: 40px
        text-decoration: none
        display: flex
        justify-content: space-between
        color: #000
        // margin: 6px 0
        align-items: center
        padding: 0 5px
        border: 2px solid rgba(0,0 ,0 ,.0 )
        >div
            display: flex
            align-items: center
    &__selected
        border: 2px solid #3376A3 !important
        background: #D7E7FF
        border-radius: 5px
        padding: 0 5px
.filePreview
    width: 12px !important
    height: 12px !important
