.item
    background: #F8F8F8
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: 5px
    height: 50px
    width: calc( 100% - 10px)
    padding-left: 10px
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 19px
    letter-spacing: 0em
    text-align: left
    &:focus
        border: 1px
    &:placeholder
        padding-left: 10px
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 19px
        letter-spacing: 0em
        text-align: left
.error
    background: #F8F8F8
    border: 2px solid rgba(255, 0, 0, 0.2)
    border-radius: 5px
    height: 50px
    width: calc( 100% - 10px)
    padding-left: 10px
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 19px
    letter-spacing: 0em
    text-align: left

.errorText
    font-family: Zeitung Micro Pro
    font-size: 10px
    font-weight: 400
    line-height: 12px
    letter-spacing: 0em
    text-align: left
    color: #FF0000
.wrap
    margin-bottom: 20px
    position: relative
    svg
        position: absolute
        top: 15px
        right: 5px
.itemSmall
    margin-top: 4px
    background: #F8F8F8
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: 5px
    height: 36px
    width: calc( 100% - 10px)
    padding-left: 10px
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 400
    line-height: 19px
    letter-spacing: 0em
    text-align: left

.errorSmall
    margin-top: 4px
    background: #F8F8F8
    border: 2px solid rgba(255, 0, 0, 0.2)
    border-radius: 5px
    height: 36px
    width: calc( 100% - 10px)
    padding-left: 10px
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 400
    line-height: 19px
    letter-spacing: 0em
    text-align: left
.errorTextSmall
    font-family: Zeitung Micro Pro
    font-size: 10px
    font-weight: 400
    line-height: 12px
    letter-spacing: 0em
    text-align: left
    color: #FF0000
