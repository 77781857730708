@import '../../../assets/styles/_variables'

.wrapper
    h2
        font-family: Zeitung Micro Pro
        font-size: 20px
        font-weight: 600
        line-height: 30px
        letter-spacing: 0em
        text-align: left

    >div
        // background: rgba(0, 0, 0, 0.5)
        >div
            border-radius: 10px,
            width: 520px
            height: 220px

    input
        width: 456px
        height: 40px
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        border-radius: 5px
        border: 2px solid #3376A3

.form
    padding-top: 15px
.createPage
    border-radius: 5px
    border: 1px solid #3376A3
    height: 30px
    &:hover
        cursor: pointer

.create
    border-radius: 10px
    color: #fff
    display: flex
    align-items: center
    padding: 10px 20px
    width: 97px
    height: 44px
    background: #3376A3
    border: 1px solid rgba(0, 0, 0, 0.1)
    justify-content: center
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: left

    &:hover
        cursor: pointer
        background: #3376A3
        &:disabled
            opacity: 0.3
