// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.wrapper

.container
    display: flex
    height: calc( 100vh - 114px )
.btn
    display: flex
    flex-wrap: wrap
    button
        display: flex
        flex-direction: column
        justify-content: initial
.project
    background: #F8F8F8
    flex-grow: 1
    color: #fff
    padding: 30px 20px 0
    // max-height: calc( 100vh - 225px )
    overflow-y: scroll
    display: flex
    flex-wrap: wrap
    align-content: start
.project::-webkit-scrollbar
    width: 0
    height: 0
.buttonGroup
    display: flexs
.header
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    padding: 0px 20px 
    height: 60px
    display: flex
    align-content: center

.flexCenter
    display: flex
    align-items: center
.breadcrumbs
    div
        text-decoration: none
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        opacity: 0.5
        color: #000
   
