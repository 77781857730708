@import '../../../assets/styles/_variables'

.wrapper

    >div
        // background: rgba(0, 0, 0, 0.5)
        >div
            border-radius: 10px,
            width: 520px
            // height: 260px
    button
        border-radius: 10px
        color: #fff
        display: flex
        align-items: center
        width: 109px
        height: 44px
        background: #3376A3
        border: 1px solid rgba(0, 0, 0, 0.1)
        justify-content: center
        margin-left: 7px
        margin-top: 20px
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: left
        padding: 0

        &:hover
            cursor: pointer
            background: #3376A3
        &:disabled

            opacity: 0.3
            &:hover
                cursor: default

    // input
    //     width: 456px
    //     height: 40px
    //     font-family: Zeitung Micro Pro
    //     font-size: 14px
    //     font-weight: 400
    //     line-height: 21px
    //     letter-spacing: 0em
    //     text-align: left
    //     border-radius: 5px



.header
    display: flex
    padding: 10px 20px
    justify-content: space-between
    >div
        display: flex
        align-items: center
        >div
            >svg
                margin: 0
                &:hover
                    cursor: pointer
    img
        width: 50px
        height: 30px
        border-radius: 5px
        margin-right: 17px
    svg
        margin-right: 27px
    span
        color: #000
        font-size: 20px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 600
        line-height: normal

// .footer
//     display: flex
//     font-family: Zeitung Micro Pro
//     font-size: 14px
//     font-weight: 400
//     line-height: 21px
//     letter-spacing: 0em
//     text-align: left
//     color: #3376A3
//     >div
//         display: flex
//         align-items: center
//         >svg
//             margin-right: 8px
// .remove
//     color: #000
//     text-align: right
//     font-size: 14px
//     font-family: Zeitung Micro Pro
//     font-style: normal
//     font-weight: 400
//     line-height: normal
//     opacity: 0.5
//     margin-right: 9px
//     &:hover
//         cursor: pointer
.removeForm
    span
        color: rgba(0, 0, 0, 0.60)
        font-size: 14px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 400
        line-height: normal


.removeBtns
    display: flex
    width: 100%
    justify-content: flex-end
    // margin-top: 15px
    button
        &:last-child
            border-radius: 5px
            border: 1px solid #3376A3
            background: rgba(51, 118, 163, 0.00)
            color: #3376A3
