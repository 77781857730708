.wrapper
    display: flex
    align-content: flex-start
    flex-direction: column
    width: 100%
    height: 100%
    padding: 20px 20px
    // overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none

    button
        display: flex
        flex-direction: column
.wrapper::-webkit-scrollbar
    width: 0
    height: 0
.projectItem
    margin-right: 20px
    margin-bottom: 30px
.thumb
    height: 140px
    width: 100%
    border-radius: 10px 10px 3px 3px
    object-fit: cover
    &__selected
        height: 140px
        width: 100%
        border-radius: 8px 8px 0px 0px
        object-fit: cover
.CardContent
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 5px 10px
    padding-bottom: 12px
.row
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    >div
        &:first-child
            width: 100%
.editTime
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    >div
        display: flex
        justify-content: space-between
.subName
    font-family: Zeitung Micro Pro
    font-size: 10px
    font-weight: 400
    line-height: 15px
    letter-spacing: 0em
    text-align: left
    color: rgba(0, 0, 0, 0.5)
    &:first-child
        margin-right: 10px
.name
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 600
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    color: rgba(0, 0, 0, 1)
.members
    display: flex
    position: relative
    height: 20px
    &__wrap
        display: flex
        justify-content: space-between
        align-items: center
    >div
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 20px
        letter-spacing: 0em
        text-align: left
        position: absolute
.sort
    display: flex
    align-items: center
    position: relative
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    margin-bottom: 46px

    color: rgb(0,0 ,0, 0.5)

    &__filter
        display: flex
        margin: 0 20px 0 5px
        color: #000
        align-items: center
        color: #000
        &:hover
            cursor: pointer
        svg
            margin-left: 5px
            &:hover
                cursor: pointer

.itemsWrapper
    display: flex
    flex-wrap: wrap
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
.itemsWrapper::-webkit-scrollbar
    width: 0
    height: 0