@import '../../../assets/styles/_variables'

.wrapper
    >div
        // background: rgba(0, 0, 0, 0.5)
        >div
            border-radius: 10px,
            width: 340px
            height: 478px

.form
    padding-top: 15px
.password
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 400
    line-height: 21px
    letter-spacing: 0em
    text-align: left
    text-decoration: underline
    opacity: 0.6
    margin-left: 10px
    &:hover
        cursor: pointer
.field
    border: 1px solid rgba(0, 0, 0, 0.1)
    height: 48px
    background: #F8F8F8
    margin-bottom: 20px
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    border-radius: 5px
    >input
        border: none
        height: 46px
        width: 100%
        background: #F8F8F8
        padding-left: 10px
        border-radius: 5px
        &::placeholder
            font-family: Zeitung Micro Pro
            font-size: 14px
            font-weight: 400
            line-height: 19px
            letter-spacing: 0em
            text-align: left

    >svg
        position: absolute
        right: 13px
        &:hover
            cursor: pointer
.changeBtn
    width: 100%
    height: 50px
    background: #3376A3
    border-radius: 5px
    border: none
    color: #FFF
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 400
    margin-bottom: 20px
    &:disabled
        opacity: 0.5
.forgot
    // font-family: Arial
    font-size: 12px
    font-weight: 400
    line-height: 14px
    letter-spacing: 0em
    text-align: left
    text-decoration: none
    color: #777777
.success
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    
    font-size: 20px
    font-weight: 600
    line-height: 24px
    letter-spacing: 0em
    text-align: center
    margin-top: 60px
    svg
        margin-bottom: 30px
