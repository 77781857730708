@import '../../../assets/styles/_variables'

.wrapper

    >div
        // background: rgba(0, 0, 0, 0.5)
        >div
            border-radius: 10px,
            // width: 520px
            // height: 352px
    button
        border-radius: 10px
        color: #fff
        display: flex
        align-items: center
        // width: 109px
        text-wrap: no-wrap
        height: 44px
        background: #3376A3
        border: 1px solid rgba(0, 0, 0, 0.1)
        justify-content: center
        margin-left: 7px
        margin-bottom: 20px
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: left
        padding: 0

        &:hover
            cursor: pointer
            background: #3376A3
        &:disabled

            opacity: 0.3
            &:hover
                cursor: default

    input
        width: 456px
        height: 40px
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        border-radius: 5px

.form
    >span
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left

.header
    display: flex
    padding: 10px 20px
    justify-content: space-between
    >div
        display: flex
        align-items: center
        >div
            >svg
                margin: 0
                &:hover
                    cursor: pointer
    img
        width: 50px
        height: 30px
        border-radius: 5px
        margin-right: 17px
    svg
        margin-right: 27px
    span
        color: #000
        font-size: 20px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 600
        line-height: normal
.searchUser
    display: flex
    align-items: flex-start
    margin-top: 3px
    >div
        // margin: 0
    input
        width: 350px
        margin: 0
.userItem
    display: flex
    align-items: center
    margin-bottom: 15px
    justify-content: space-between
    >div
        display: flex
        align-items: center
        margin-right: 8px
    &__name
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        margin-left: 8px
.users
    height: 132px
    overflow-y: scroll
//     -ms-overflow-style: none
//     scrollbar-width: none
// .users::-webkit-scrollbar
//     display: none
.footer
    display: flex
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 400
    line-height: 21px
    letter-spacing: 0em
    text-align: left
    color: #3376A3
    >div
        display: flex
        align-items: center
        >svg
            margin-right: 8px
.remove
    color: #000
    text-align: right
    font-size: 14px
    font-family: Zeitung Micro Pro
    font-style: normal
    font-weight: 400
    line-height: normal
    opacity: 0.5
    &:hover
        cursor: pointer
.removeForm
    span
        color: rgba(0, 0, 0, 0.60)
        font-size: 14px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 400
        line-height: normal
    >div
        margin-bottom: 28px

.removeBtns
    display: flex
    width: 100%
    justify-content: flex-end
    margin: 0
    button
        &:last-child
            border-radius: 5px
            border: 1px solid #3376A3
            background: rgba(51, 118, 163, 0.00)
            color: #3376A3
.search
    top: 145px
    position: absolute
    height: 150px
    width: 346px
    border-radius: 5px
    padding: 0px 10px
    display: flex
    flex-direction: column
    background: #1F1F1F
    color: #FFF
    font-size: 12px
    font-family: Zeitung Micro Pro
    font-style: normal
    font-weight: 400
    line-height: normal
    z-index: 1500
    overflow: scroll
    >div
        opacity: 0.5
        padding: 4px 0px
        &:hover
            cursor: pointer
            opacity: 1
    &__overload
        top: 145px
        position: absolute
        z-index: 1499
        width: 520px
        height: 352px
        top: 0
        left: 0
