.wrapper
    display: flex

    flex-direction: column
.header
    height: 70px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    display: flex
    align-items: center
    padding: 0 20px
    margin-bottom: 30px
    >svg
        &:hover
            cursor: pointer
    >div
        display: flex
        align-items: center
        span
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left

        >svg
            margin-left: 21px
            margin-right: 6px
.content
    height: calc( 100vh - 150px )
    display: flex
    flex-direction: column
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    padding: 0 10px

.content::-webkit-scrollbar
    display: none
.items
    display: flex
    flex-wrap: wrap
    margin-bottom: 23px

.title
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 600
    line-height: 24px
    letter-spacing: 0em
    text-align: left
    display: flex
    align-items: center
    margin-bottom: 43px
    &:hover
        cursor: pointer

    >svg
        margin-right: 5px
