@import '../../../assets/styles/_variables'

.wrapper
    h2
        font-family: Zeitung Micro Pro
    >div
        background: rgba(0, 0, 0, 0.5)
        >div
            width: 500px
            // height: 450px
    >button
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: center
        padding: 0 5
        font-weight: 400
        margin: 0 5px
        background: transparent
        box-shadow: none
        color: #000
        border: 1px solid gray
        border-radius: 10px
        height: 100%
        padding: 0 10px
        &:hover
            cursor: pointer
            // background: #3376A3
        &:disabled
            opacity: 0.3
            color: #fff

.form
    padding-top: 15px
.flex
    display: flex
    margin-bottom: 15px
    >button
        white-space: nowrap
        height: 100%
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: center
        background: #3376A3
        color: #fff
        border: 1px solid rgba(0, 0, 0, 0.2)
        border-radius: 5px
        &:disabled
            opacity: 0.3
            background: #3376A3

    >input
        margin-right: 15px
        border: 1px solid rgba(0, 0, 0, 0.2)
        border-radius: 5px
        width: 357px
        height: 24px
    >div>input
        margin-right: 15px
        border: 1px solid rgba(0, 0, 0, 0.2)
        border-radius: 5px
        width: 357px
        height: 24px
        position: relative

.footer
    display: flex
    align-items: center
    justify-content: space-between
    >div
        display: flex
        align-items: center
        svg
            margin-right: 5px
        &:last-child
            cursor: pointer
.searchResponse
    position: absolute
    top: 10
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
.relative
    position: relative
