@import '../../../assets/styles/_variables'

.wrapper
    >div
        // background: rgba(0, 0, 0, 0.5)
        >div
            border-radius: 10px,
            width: 980px
            max-width: 980px
            // height: 255px

    input
        width: 456px
        height: 40px
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        border-radius: 5px

.item
    display: flex
    align-items: center
    padding: 9px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)

    >div
        width: 100%
        display: flex
        flex-wrap: nowrap
        white-space: nowrap
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        align-items: flex-start
        &:last-child
            display: flex
            justify-content: space-between
            align-items: center
            >svg
                &:hover
                    cursor: pointer
            >div
                display: flex
                align-items: center

        >img
            height: 20px
            width: 30px
        >a
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left
            color: #5A54F5
        >span
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left
            opacity: 0.5

.header
    display: flex
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
    padding: 16px 0
    >div
        width: 100%
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        opacity: 0.5
