.wrapper
    background: #F8F8F8
    height: 100vh
    width: 100vw
    overflow: scroll
    color: #000
    position: absolute
    top: 0
    left: 0
    z-index: 1500
.wrapper::-webkit-scrollbar
    display: none
.wrapper
    -ms-overflow-style: none
    scrollbar-width: none
.header
    img
        width: 100%
        max-height: 576px
        object-fit: contain
.content
    margin: 0 auto
.title
    font-family: Zeitung Micro Pro
    font-size: 36px
    font-weight: 600
    line-height: 54px
    letter-spacing: 0em
    text-align: center
    margin: 80px 0 22px
    text-align: left
.column
    display: flex
    flex-direction: column
    margin: 0 auto
    width: 500px
.info
    font-family: Zeitung Micro Pro
    font-weight: 600
    font-size: 14px
    &__drop
        background: #D7E7FF
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        border-bottom-right-radius: 10px
        border-bottom-left-radius: 10px

    &__item
        background: #D7E7FF
        border-radius: 3px
        display: flex
        align-items: center
        justify-content: space-between
        flex-direction: column
        margin-bottom: 15px
        div
            padding: 10px 20px
            box-sizing: border-box
        &__active
            display: none
            display: flex
            align-items: center
            width: 100%
            justify-content: space-between
            border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        &__noActive
            display: none
            display: flex
            align-items: center
            width: 100%
            justify-content: space-between
.question
    margin-bottom: 15px
    input
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left

        width: 100%
        height: 40px
        background: #F8F8F8
        border: 1px solid rgba(0, 0, 0, 0.2)
        border-radius: 5px
    span
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
.form
    padding-bottom: 15px
    button
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: left
        padding: 0px 12px
        background: #3376A3
        border-radius: 5px
        // width: 60px
        height: 44px
        color: #fff
        &:disabled
            opacity: 0.3
.footer
    &__terms
        height: 350px
        overflow-y: scroll
        margin-bottom: 20px
    &__check
        display: flex
        align-items: center
        margin-bottom: 20px
        >span
            font-family: Zeitung Micro Pro
            font-size: 14px
            font-weight: 400
            line-height: 21px
            letter-spacing: 0em
            text-align: left

        >svg
            margin-right: 8px

.subTitle
    color: #000
    font-family: Zeitung Micro Pro
    font-size: 20px
    font-style: normal
    font-weight: 600
    line-height: normal
    margin: 61px 0 40px
.checker
    margin: 16px 0 0 0
    &__item
        display: flex
        margin-bottom: 10px
        svg
            margin-right: 5px
.media
    display: flex
    flex-direction: column
    font-weight: 400
    font-size: 14px
    margin-bottom: 40px
    
    &:first-child
        margin-top: 40px
    span
        margin-bottom: 6px
    input
        display: none
    &__wrapper
        background: #F0F0F0
        height: 150px
        border-radius: 5px
        display: flex
        justify-content: center
        align-items: center
        position: relative
        >img 
            width: 100%
            height: 100%
            object-fit: contain
        >video
            height: 100%
            width: 100%
        >button
            position: absolute
            background: transparent
            color: #3376A3
            border: 1px solid #3376A3
            border-radius: 10px
            padding: 4px 8px
    &__description
        font-weight: 400
        font-size: 10px
        opacity: 0.5
        margin-top: 6px