.breadcrumbs
    &__thumb
        width: 30px
        height: 20px
        margin-right: 5px
    &__thumbFile
        width: 20px
        height: 20px
        margin-right: 5px
    li
        &:last-child>a
            opacity: 1