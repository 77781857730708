@import '../../assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'

.wrapper
    display: flex
    max-width: 500px
    height: calc( 100vh - 121px )
    width: auto
    background: #F8F8F8
    border-left: 1px solid rgba(0, 0, 0, 0.1)
.tabPanelWrapper
    width: 250px
    height: calc( 100vh - 121px )
    color: #000000
    background: #fff

.asside
    padding: 17px 10px 0
    &__title
        // color: #443939
        // font-family: 'Inter'
        font-style: normal
        font-weight: 400
        font-size: 16px
    &__label
        margin-right: 15px
    &__link
        color: #fff
        text-decoration: none
        display: flex
        height: 40px
        align-items: center
        width: 100%
        padding: 0 10px
        >svg
            margin-right: 10px
        &__wrapper
            padding-top: 15px
            pa
        &__active
            background: #3C3C3C
.tab
    width: 20px
    height: 20px
    // background: $lightGray
    background-color: #fff
    border-radius: 5px

.commentsSection
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    height: 90%
    img
        margin-right: 14px
        color: #fff
    &__header
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        display: flex
        padding-bottom: 18px
        div
            display: flex
            align-items: center
        span
            // font-family: 'Inter'
            font-style: normal
            font-weight: 400
            font-size: 12px
            line-height: 15px
            margin-left: 7px
    &__comment
        padding-top: 10px
        &__name
            font-size: 20px
        &__text
            font-size: 14px
            margin-bottom: 10px
        &__when
            font-size: 11px
            margin-bottom: 12px
    &__input
        border: 1px solid rgba(0, 0, 0, 0.12)
        border-radius: 5px
    &__send
        background: #3376A3
    &__form
        display: flex
        button
            border-radius: 45px
            padding: 9px 12px
            border: none
            margin-left: 5px
            &:disabled
                opacity: 0.5

.commentsSection::-webkit-scrollbar
    display: none
.commentsWrap
    height: 100%

.wrap
    height: 100%
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    margin-bottom: 25px
.wrap::-webkit-scrollbar
    display: none
.info
    &__wrapper
        display: flex
        flex-direction: column
        height: calc( 100vh - 171px )
        overflow: scroll
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
    // &__input
    //     height: 20px
    &__header
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        display: flex
        padding-bottom: 18px
        div
            display: flex
            align-items: center
        span
            // font-family: 'Inter'
            font-style: normal
            font-weight: 400
            font-size: 12px
            line-height: 15px
            margin-left: 7px
    &__label
        font-size: 10px
        display: flex
        align-items: center
        font-family: 'Zeitung Micro Pro'
        font-style: normal
        font-weight: 400
        font-size: 10px
        line-height: 15px
        opacity: 0.5
        >span
            font-family: 'Zeitung Micro Pro'
            font-style: normal
            font-weight: 400
            font-size: 12px
            line-height: 15px
            margin-left: 7px
            color: #000
    &__item
        font-size: 12px
        font-family: 'Zeitung Micro Pro'
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 18px
    &__preview
        height: 124px
        width: 210px
        object-fit: contain
        border-radius: 3px
    &__column
        display: flex
        flex-direction: column
        padding: 10px 0
        // overflow: hidden
