
.overview
    height: 100vh
    width: 100vw
    position: fixed
    top: 0
    left: 0

.overviewNone
    height: 100vh
    width: 100vw
    position: fixed
    top: 0
    left: 0
    display: none
.wrapper
    position: absolute
    width: 350px
    min-height: 170px
    max-height: calc( 100vh - 241px )
    background: red
    right: 26px
    top: 58px
    border-radius: 10px
    padding: 10px 11px
    background: #1F1F1F
    display: flex
    flex-direction: column
.title
    font-size: 12px
    font-style: normal
    font-weight: 700
    margin-bottom: 10px
.item
    padding: 10px 0
    display: flex
    // justify-content: space-between
    width: 100%
    &:hover
        cursor: pointer
    >div
        &:first-child
            margin-right: 10px
    &__text
        >div
            font-size: 10px
            font-weight: 400
            opacity: 0.5
        span
            font-size: 12px
            &:nth-child(1)
                font-weight: 700
            &:nth-child(2)
                font-weight: 400

.items
    display: flex
    flex-direction: column
    height: 100%
    overflow: scroll

.items::-webkit-scrollbar
    display: none
.items
    -ms-overflow-style: none
    scrollbar-width: none
    z-index: 1500
