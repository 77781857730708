// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  

.flexRow
    display: flex
    justify-content: space-between
    height: 100%
.flexCenter
    display: flex
    align-items: center
.wrapper
    background: #F8F8F8
    height: 100%
    display: flex
    flex-direction: column
    // height: calc( 100vh - 50px )
    // overflow-y: scroll
    color: #000
.wrapper::-webkit-scrollbar
    display: none
.wrapper
    -ms-overflow-style: none
    scrollbar-width: none
.header
    display: flex
    justify-content: space-between
    height: 70px
    align-items: center
    padding: 0 10px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    >button
        border-radius: 10px
        color: #fff
        display: flex
        align-items: center
        padding: 10px 20px
        gap: 5px
        width: 97px
        height: 44px
        background: #3376A3
        border: 1px solid rgba(0, 0, 0, 0.1)
        &:hover
            background: #3376A3
    a
        text-decoration: none
        color: #CACACA

.caruselWrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px 0 0 0
.item
    // width: 100%
    // height: calc( 100vh - 190px )
    margin-top: 59px
    display: flex
    height: 221px
    max-width: 214px
    margin-right: 17px


.itemVideo
    display: flex
    height: 100%
    margin-right: 17px
.bottomImages
    display: flex
    margin-bottom: 31px
    img
        // height: 221px
.currentImage
    display: flex
    flex-grow: 2
    align-items: center
    justify-content: center
    // width: 70%
    height: 100px
    img
        // width: 100%
        height: 100%
        object-fit: contain

.bottomImage
    display: flex
    height: 221px
    max-width: 214px
    margin-top: 59px
    margin-right: 17px
    img
        height: 100%
        width: 100%
        object-fit: scale-down
        border-radius: 3px
