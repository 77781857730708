@import '../../assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'

.wrapper
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    // margin-left: 15px
    // height: calc( 100vh - 145px )
    height: 100%
    display: flex
    flex-direction: column
    padding: 19px 35px 0 30px
    >div>div>img
        width: 62px
        height: 50px
        border-radius: 5px
        object-fit: cover
        margin-right: 13px

.wrapper::-webkit-scrollbar
    display: none
.item
    display: flex
    padding: 10px 18px
    align-items: center
    justify-content: space-between
    // width: 100%
    >div
        display: flex
        width: 20%
        align-items: center
        justify-content: center
        &:first-child
            justify-content: flex-start
        &:last-child
            justify-content: flex-end
    span
        // color: #FFF
        text-align: center
        font-size: 12px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 700
        line-height: normal
.itemGray
    display: flex
    padding: 10px 18px
    align-items: center
    justify-content: space-between
    background: #1A1A1A
    >div
        display: flex
        width: 20%
        align-items: center
        justify-content: center
        &:first-child
            justify-content: flex-start
        &:last-child
            justify-content: flex-end
    span
        // color: #FFF
        text-align: center
        font-size: 12px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 700
        line-height: normal
.folder
    >div
        border-radius: 20px
        opacity: 0.6
        background: rgba(255, 255, 255, 0.20)
        // color: #FFF
        font-size: 12px
        // font-family: Arial
        font-style: normal
        font-weight: 700
        line-height: 100%
        text-transform: uppercase
        display: flex
        padding: 9px 10px
.options
    display: flex
    svg
        &:hover
            cursor: pointer
.itemSelected
    border-radius: 5px
    border: 1px solid rgba(255, 255, 255, 0.30)
