
.card
    &__likeComment
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        margin-top: 12px

        font-size: 10px
        font-weight: 400
        line-height: 12px
        letter-spacing: 0em
        text-align: left

        div
            display: flex
            align-items: center
        svg ,span
            margin-right: 5px
            color: #000
            font-size: 10px
            font-weight: 400
            line-height: 12px
            letter-spacing: 0em
            text-align: left

.flexBetween
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    span
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        color: #000000
        text-transform: none

.folder
    &__thumb
        height: 116px
        width: 100%
        object-fit: cover
        border-radius: 3px
    &__thumbFile
        height: 130px
        width: 100%
        border-radius: 8px 8px 3px 3px
        object-fit: contain
        background: #F8F8F8
    &__wrapper
        display: flex
        justify-content: space-around
        height: 115px
        >img
            height: 100%
            width: 223px
            object-fit: cover
            border-radius: 3px
        >div
            display: flex
            flex-direction: column
            justify-content: space-between
            >img
                height: 50px
                width: 50px
                object-fit: cover
                border-radius: 3px

            >div
                height: 50px
                width: 50px
                display: flex
                justify-content: center
                align-items: center
                background-color: rgb(51, 118, 163,30%)
                border-radius: 5px
                >span
                    color: #3376A3
.empty
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    background: rgb(197, 214, 228, 40% )
    border-radius: 5px
    width: 100%
    span
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 15px
        letter-spacing: 0em
        text-align: center
        color: #3376A3
        width: 90px
