// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  
.wrapper
    padding: 20px
    input
        background: #F8F8F8
        border: 1px solid rgba(0, 0, 0, 0.1)
        border-radius: 5px
        height: 30px
        padding-left: 10px
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 19px
        letter-spacing: 0em
        text-align: left

        &:placeholder
            padding-left: 10px
            font-family: Zeitung Micro Pro
            font-size: 16px
            font-weight: 400
            line-height: 19px
            letter-spacing: 0em
            text-align: left
    button
        border: none
        border-radius: 5px
        font-family: "Zeitung Micro Pro"
        font-style: normal
        font-weight: 400
        font-size: 16px
        height: 40px
        background: #3376A3
        color: #fff
