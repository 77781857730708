@import '../../../assets/styles/_variables'

.overlay
    // background: red
    // opacity: 10
.wrapper

    >div
        >div
            width: 520px
    h2
        font-family: Zeitung Micro Pro
        font-size: 20px
        font-weight: 600
        line-height: 30px
    div
        >div
            color: #000
            border-radius: 10px

        p, button, label
            color: #000
        label
            color: #000
        textarea, input
            // background: $lightGray
.form
    padding-top: 15px
    >input
        background: #F8F8F8
        border: 1px solid rgba(0, 0, 0, 0.2)
        border-radius: 5px
        width: 100%
        height: 36px
        margin-bottom: 30px
        margin-top: 10px
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        &:focus
            border: 2px solid #3376A3

.title
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 600
    line-height: 21px
    letter-spacing: 0em
    text-align: left

.subTitle
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    opacity: 0.5
    idth: 209px
.thumb
    background: #F8F8F8
    border: 1px dashed #656565
    border-radius: 5px
    height: 150px
    display: flex
    align-items: center
    justify-content: center
    img
        width: 100%
        height: 100%
        object-fit: contain
.createBtn
    background: #3376A3 !important
    color: #fff
    padding: 5px 15px
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: left
    color: #fff !important
    &:disabled
        opacity: 0.3
.emptyProjects
    display: flex
    height: 183px
    width: 305px
    color: #3376A3
    border-radius: 10px
    border: 1px solid #3376A3
    align-items: center
    justify-content: center
    &:hover
        cursor: pointer
    span
        color: #3376A3
        font-size: 16px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 400
        line-height: normal
.selectImg
    >div
        display: flex
        justify-content: center
        width: 150px
        background: #3376A3
        border-radius: 10px
        border: none
        font-size: 12px
        color: #fff
        margin-top: 10px
        &:hover
            cursor: pointers