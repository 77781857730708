.media
    width: 100%
    height: 25rem
    object-fit: contain
.wrapper
    width: 100%

.carousel
    position: relative
    // width: 400px

.item
    background-color: black
    display: flex
    justify-content: center
.itemMini
    // background-color: red  !important
    // display: flex
    // justify-content: center
    img
        height: 100px
        width: 165px
.index
    position: absolute
    top: 0.1rem
    font-size: 0.7rem
    font-weight: bold
    font-family: Roboto
    color: black
    padding: 0.1rem 0.3rem
    background-color: lightgray
    left: 1rem
    opacity: 0.75

.captionContainer
    position: absolute
    bottom: 0
    left: 20rem
    right: 20rem

.caption
    padding: 0.3rem 0.5rem
    font-size: 0.8rem
    font-weight: bold
    font-family: Roboto
    background-color: lightgray
    color: black
    width: fit-content
    margin: 0 auto
    opacity: 0.75

.btnNext
    position: absolute
    font-size: 50px
    color: lightgray
    cursor: pointer
    top: 13rem
    right: -22px
.btnPrev
    position: absolute
    font-size: 50px
    color: lightgray
    cursor: pointer
    top: 13rem
    left: -22px
.footer
    font-family: 'Zeitung Micro Pro'
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 18px
    display: flex
    justify-content: space-between
    margin-top: 15px
