
.wrapper
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    background: #F8F8F8

    >div
        margin: 150px auto
        background: #FFF
        border-radius: 10px
        padding: 50px 47px 36px
        display: flex
        flex-direction: column
        // align-items: center
    .title
        font-size: 32px
        font-weight: 700
        line-height: 44px
        letter-spacing: 0em
        text-align: left
        margin: 20px 0 0
        width: 100%
    .fields
        display: flex
        flex-direction: column
        padding-top: 20px
        width: 300px
        >div>div
            margin-bottom: 14px
    button
        background: #FF7BAC
        margin-bottom: 40px
        width: 300px
        font-size: 16px
        font-weight: 400
        line-height: 19px
        letter-spacing: 0em
        text-align: left
        height: 50px
        color: #fff
        font-family: "Zeitung Micro Pro"
        border-radius: 5px
        border: none
        display: flex
        align-items: center
        justify-content: center
        &:disabled
            // background: #FF7BAC
            opacity: 0.5
            color: #fff
        &:hover
            background: #FF7BAC
            cursor: pointer
    .links
        display: flex
        justify-content: space-between
        margin-bottom: 15px
        font-size: 12px
        width: 300px
        >a
            text-decoration: none
            color: #777777
            &:hover
                color: #3376A3
.banner
    position: fixed
    bottom: 15px
    left: calc( 50vw - 241px )
    width: 500px
    border-radius: 10px
