// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  

.flexRow
    display: flex
    padding: 15px
    flex-wrap: wrap
    >a
        margin-left: 15px
        margin-bottom: 15px
.flexCenter
    display: flex
    align-items: center
.wrapper
    background: #F8F8F8
    height: 100%
    max-height: calc( 100vh - 50px )
    overflow-y: scroll
    color: #000
.wrapper::-webkit-scrollbar
    display: none
.wrapper
    -ms-overflow-style: none
    scrollbar-width: none
.header
    display: flex
    justify-content: space-between
    height: 70px
    align-items: center
    padding: 0 10px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left

    >button
        border-radius: 10px
        color: #fff
        display: flex
        align-items: center
        padding: 10px 20px
        gap: 5px
        width: 97px
        height: 44px
        background: #3376a3
        border: 1px solid rgba(0, 0, 0, 0.1)
        &:hover
            background: #3376A3

.breadcrumbs
    display: flex
    align-items: center
    a
        text-decoration: none
        color: #CACACA
