@import '../../assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'

.wrapper
    height: 60px
    background: #2C2C2C
    width: 100vw
    display: flex
    align-items: center
    span
        color: #fff

.logo
    display: flex
    align-items: center
    border-right: rgba(255, 255, 255, 0.10)
    height: 100%
    padding: 0px 75px 0 15px
    svg
        margin: 0 13px
    span
        color: #FFFFFF
        opacity: 0.5
        font-family: 'Zeitung Micro Pro'
        font-style: normal
        font-weight: 700
        font-size: 14px
.leftSide
    display: flex
    justify-content: space-between
    align-items: center
    color: #fff
    width: 100%
    padding: 0 7px 0 15px
    justify-content: end

.buttons
    button
        background: transparent
        border: 1px solid rgba(255, 255, 255, 0.1)
        padding: 8px 10px
        border-radius: 10px
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: center
        color: #fff
        margin-right: 8px
        &:last-child
            background: #3376A3
        &:hover
            cursor: pointer
