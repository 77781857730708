.result
    position: absolute
    height: 300px
    width: 200px
    background: #1F1F1F
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    color: #fff
    z-index: 1500
    a
        color: #fff
        text-decoration: none