@import '../../assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'

.wrapper
    display: flex
    // min-width: 70px
    max-width: 500px
    height: calc( 100vh - 50px )
    width: auto
    background: #F8F8F8

.tabPanelWrapper
    // height: 100%
    padding: 20px
    color: #000000
    background: #fff
    width: 210px
    border-left: 1px solid rgba(0, 0, 0, 0.1)
.tabPanelWrapperActive
    // height: 100%
    padding: 20px
    color: #000000
    background: #D7E7FF
    width: 210px
    border: 2px dashed #3376A3
.asside
    padding: 17px 10px 0
    &__title
        // color: #443939

        font-style: normal
        font-weight: 400
        font-size: 16px
    &__label
        margin-right: 15px
    &__link
        color: #fff
        text-decoration: none
        display: flex
        height: 40px
        align-items: center
        width: 100%
        padding: 0 10px
        >svg
            margin-right: 10px
        &__wrapper
            padding-top: 15px
            pa
        &__active
            background: #3C3C3C
.tab
    width: 20px
    height: 20px
    // background: $lightGray
    background-color: #fff
    border-radius: 5px

.content::-webkit-scrollbar
    display: none

.content
    -ms-overflow-style: none
    scrollbar-width: none
.content
    height: calc( 100vh - 105px ) // - 20px paddind and - 15px margin
    // overflow: scroll
    span
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left

    &__header
        display: flex
        justify-content: space-between
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        margin-bottom: 20px
        padding-bottom: 15px
        div
            &:hover
                cursor: pointer
    &__item
        display: flex
        flex-direction: column
        padding: 8px
        border-radius: 10px
        border: 1px solid rgba(0,0 ,0 ,.0 )
        img
            width: 192px
            height: 100px
            border-radius: 10px
            object-fit: contain
        &__selected
            display: flex
            flex-direction: column
            background: rgba(51, 118, 163, 0.1)
            border: 1px solid #3376A3
            padding: 8px
            border-radius: 10px
            img
                width: 192px
                height: 100px
                border-radius: 10px
                object-fit: contain

    &__footer
        display: flex
        align-items: center
        justify-content: space-between
        margin-top: 4px
        // height: 23px
        >div
            font-family: 'Zeitung Micro Pro'
            font-style: normal
            font-weight: 400
            color: #000
            font-size: 12px
            line-height: 18px
            >span
                color: #3376A3
                margin-right: 5px

.dropZone
    height: 100%
    width: 100%
    border-radius: 10px
    overflow: scroll
    >div
        &:last-child
            margin-bottom: 20px

.dropZone::-webkit-scrollbar
    display: none

.dropZone
    -ms-overflow-style: none
    scrollbar-width: none

.dropZoneFolder
    height: 100%
    overflow: scroll
    >div
        &:last-child
            margin-bottom: 20px

.dropZoneFolder::-webkit-scrollbar
    display: none

.dropZoneFolder
    -ms-overflow-style: none
    scrollbar-width: none

.edit
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 600
    line-height: 18px
    letter-spacing: 0em
    height: 100%
    form
        height: 100%
    &__header
        display: flex
        justify-content: space-between
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        margin-bottom: 20px
        padding-bottom: 15px
    &__column
        display: flex
        flex-direction: column
        height: calc( 100vh - 184px )
        overflow: scroll
        -ms-overflow-style: none
        scrollbar-width: none
    &__column::-webkit-scrollbar
        display: none

    &__logo
        width: 50px
        height: 50px
        background: gray
        border-radius: 4px
        margin-bottom: 5px
        display: flex
        img
            width: 100%
            object-fit: contain
    &__flex
        display: flex
        align-items: center
        margin-bottom: 20px
        input
            &[type="file"]
                display: none
        >label
            padding: 10px
            margin-left: 20px
            font-family: 'Zeitung Micro Pro'
            font-style: normal
            font-weight: 400
            font-size: 12px
            line-height: 18px
            border-radius: 10px
            background: transparent
            border: 1px solid rgba(0, 0, 0, 0.1)
            &:hover
                cursor: pointer
    &__field
        display: flex
        flex-direction: column
        margin-bottom: 15px
        // height: 100%
        input
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left
            background: #F8F8F8
            border: 1px solid rgba(0, 0, 0, 0.1)
            border-radius: 5px
            height: 36px
        >span
            font-family: Zeitung Micro Pro
            font-size: 10px
            font-weight: 400
            line-height: 15px
            letter-spacing: 0em
            text-align: left
            color: rgba(0, 0, 0, 0.6)
        >textarea
            resize: none
            height: 150px
        &:last-child
            // height: calc( 100vh - 255px )

    &__files::-webkit-scrollbar
        display: none

    &__files
        overflow: scroll
        margin-bottom: 7px
        -ms-overflow-style: none
        scrollbar-width: none
        >div
            display: flex
            flex-direction: column

        img
            height: 124px
            width: 210px
            border-radius: 3px
            object-fit: contain
        img,input
            margin-bottom: 5px
    &__footer
        display: flex
        justify-content: space-between
        button
            font-family: Zeitung Micro Pro
            height: 40px
            border-radius: 10px
            border: none
            padding: 10px
            font-size: 14px
            &:first-child
                background: #3376A3
                color: #fff
                margin-right: 12px
            // &:last-child
            //     color: #000      //FOR CANCEL BTN
            //     background: #F8F8F8
            &:hover
                cursor: pointer
    &__shareBtns
        display: flex
        flex-direction: column
        >a
            border: 1px solid rgba(0, 0, 0, 0.10)
            margin-bottom: 30px
            text-decoration: none
            background: #f8f8f8
            color: #3376A3
            height: 40px
            display: flex
            align-items: center
            justify-content: center
            border-radius: 10px
            // margin-bottom: 5px
            font-weight: 400
            font-size: 14px
            >svg
                margin-left: 8px
            &:hover
                cursor: pointer
        >button
            border: 1px solid rgba(0, 0, 0, 0.10)
            margin-top: 12px
            height: 40px
            border-radius: 10px
            color: #000
            background: #F8F8F8
            font-weight: 400
            font-size: 14px
            margin-bottom: 30px
            font-family: Zeitung Micro Pro
            display: flex
            align-items: center
            justify-content: center
            >svg
                margin-left: 8px
            &:hover
                cursor: pointer

.directories
    display: flex
    flex-direction: column
    &__selectItem
        display: flex
        align-items: center
        justify-content: space-between
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        background: #D7E7FF
        height: 40px
        border-radius: 5px
        padding: 0 10px
        div
            display: flex
            align-items: center
            svg
                &:first-child
                    margin: 0 5px
                &:last-child
                    margin: 0 5px
    &__item
        display: flex
        align-items: center
        justify-content: space-between
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 600
        line-height: 18px
        letter-spacing: 0em
        text-align: left
        background: #F8F8F8
        height: 40px
        border-radius: 5px
        padding: 0 10px
        div
            display: flex
            align-items: center
            svg
                &:first-child
                    margin: 0 5px
                &:last-child
                    margin: 0 5px

    &__files
        display: flex
        flex-direction: column

        &__item
            display: flex
            align-items: center
            font-family: Zeitung Micro Pro
            font-size: 12px
            font-weight: 400
            line-height: 18px
            letter-spacing: 0em
            text-align: left
            padding: 0 10px
            margin: 10px 0 10px
            &__selected
                display: flex
                align-items: center
                justify-content: space-between
                font-family: Zeitung Micro Pro
                font-size: 12px
                line-height: 18px
                letter-spacing: 0em
                text-align: left
                background: #F8F8F8
                height: 40px
                padding: 0 10px
                background: #D7E7FF
                border: 2px solid #3376A3
                border-radius: 5px
                img
                    height: 20px
                    width: 20px
                    margin: 0 5px
                >div
                    display: flex
                    align-items: center
            div
                display: flex
                align-items: center
            svg
                &:first-child
                    margin-right: 5px

            img
                height: 20px
                width: 20px
                margin: 0 5px
.DropWrap
    display: flex
    flex-direction: column
    align-content: center
    align-items: center
    justify-content: center
    height: calc( 100vh - 135px )
    span

        font-size: 12px
        font-weight: 400
        line-height: 15px
        letter-spacing: 0em
        text-align: center
        color: #3376A3
        max-width: 129px
    svg
        margin-bottom: 17px
    button
        color: #3376A3
        background: transparent
        font-family: 'Zeitung Micro Pro'
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 18px
        border: 1px solid #3376A3
        border-radius: 5px
        margin-top: 5px
.styles
    &__label
        font-family: Zeitung Micro Pro
        font-size: 10px
        font-weight: 700
        line-height: 15px
        letter-spacing: 0em
        text-align: left
        margin-bottom: 12px

    &__item
        font-family: Zeitung Micro Pro
        font-size: 10px
        font-weight: 400
        line-height: 15px
        letter-spacing: 0em
        text-align: left
        color: rgb(0,0,0,0.5)
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        &:last-child
            margin-bottom: 10px
        >div
            display: flex
            align-items: center
            span
                color: #000
    &__wrap
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        margin-bottom: 32px
    &__flex
        display: flex
        justify-content: space-between
        margin: 0
        align-items: center
        >div
            margin-bottom: 10px
        svg
            &:hover
                cursor: pointer
.hightlight
    border: 2px dashed #3376A3
    margin-bottom: 5px
    border-radius: 5px
    &__item
        display: flex
        flex-direction: column
        align-items: center
        background: #D7E7FF
        span

            font-size: 12px
            font-weight: 400
            line-height: 15px
            letter-spacing: 0em
            text-align: center
            color: #3376A3
            width: 109px
            margin-bottom: 15px
.folder
    margin-bottom: 5px
