// @import '../../components/assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'  

.flexRow
    display: flex
    flex-wrap: wrap
    padding: 15px
    flex-grow: 2
    overflow-y: scroll
    align-content: start
    a
        margin: 0 15px 15px 0
.flexCenter
    display: flex
    align-items: center
.wrapper
    background: #F8F8F8
    height: 100%
    // max-height: calc( 100vh - 50px )
    // overflow-y: scroll
    color: #000
    display: flex
    flex-direction: column
.wrapper::-webkit-scrollbar
    display: none
.wrapper
    -ms-overflow-style: none
    scrollbar-width: none
.header
    display: flex
    justify-content: space-between
    height: 70px
    align-items: center
    padding: 0 10px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    font-family: Zeitung Micro Pro
    font-size: 12px
    font-weight: 400
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    &__btns
        display: flex
        >a
            font-family: Zeitung Micro Pro
            font-size: 16px
            font-weight: 400
            border-radius: 10px
            display: flex
            align-items: center
            padding: 5px 10px
            // height: 44px
            border: 1px solid rgba(0, 0, 0, 0.1)
            margin-right: 13px
            background: transparent
            color: #000
            text-decoration: none
        >button
            font-family: Zeitung Micro Pro
            font-size: 16px
            font-weight: 400
            border-radius: 10px
            color: #fff
            display: flex
            align-items: center
            padding: 5px 10px
            height: 44px
            background: #3376A3
            border: 1px solid rgba(0, 0, 0, 0.2)
            &:first-child
                margin-right: 13px
                background: transparent
                color: #000
            &:hover
                cursor: pointer
    &__link
        text-decoration: none
        color: #CACACA
.empty
    display: flex
    background: #F8F8F8
    justify-content: center
    width: 100%
    align-items: center
    flex-grow: 2
.casting_item
    width: 307px
    background: #F8F8F8
    border-radius: 10px
    border: 1px solid rgba(0, 0, 0, 0.2)
    margin: 30px 15px 0 0
    &__content
        color: #000
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-style: normal
        font-weight: 600
        line-height: normal
        padding: 10px 7px
        span
            color: #000
            font-family: Zeitung Micro Pro
            font-size: 10px
            font-style: normal
            font-weight: 400
            line-height: normal
            opacity: 0.5
    &:hover
        cursor: pointer
