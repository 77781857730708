@import '../../assets/styles/_variables'
// @import '../../components/assets/styles/_mixin'
.overlay
    height: 100vh
    width: 100vw
    position: fixed
    top: 0
    left: 0
    z-index: 9999
    pointer-events: auto
.wrapper
    position: absolute
    display: flex
    flex-direction: column
    height: 230px
    width: 400px
    background: #242424
    z-index: 10000
    right: 15px
    top: 64px
    padding: 10px 15px
    color: #fff
    border-radius: 10px
.content
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none

.content::-webkit-scrollbar
    display: none

.title
    display: flex
    justify-content: center
    position: relative

    font-size: 12px
    font-weight: 400
    line-height: 15px
    letter-spacing: 0em
    text-align: center

    >svg
        position: absolute
        right: 0
        &:hover
            cursor: pointer
.item
    border-bottom: 1px solid $lightGray
    padding: 11px
    display: flex
    &__info
        display: flex
        flex-direction: column
        font-size: 10px
        max-width: 250px
        &__progress
            width: 250px
            border: 1px solid $lightGray
            border-radius: 18px
            height: 6px
            >span
                height: 6px
                border-radius: 18px
                background-color: $lightGray
                >span
                    background-color: #5A54F5
