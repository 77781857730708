@import '../../../assets/styles/_variables'

.wrapper
    >div
        background: rgba(0, 0, 0, 0.5)
        >div
            width: 500px
            -ms-overflow-style: none
            scrollbar-width: none
        >div::-webkit-scrollbar
            display: none

    // >button
    //     font-family: Zeitung Micro Pro
    //     font-size: 16px
    //     font-weight: 400
    //     line-height: 24px
    //     letter-spacing: 0em
    //     text-align: center
    //     background: #3376A3
    //     border: 1px solid rgba(0, 0, 0, 0.1)
    //     border-radius: 10px
    //     color: #fff
    //     padding: 10px 20px
    //     &:hover
    //         cursor: pointer
    //         // background: #3376A3
    //     &:disabled
    //         opacity: 0.3
    //         color: #fff
.wrapper::-webkit-scrollbar
    display: none
.form
.thumb
    background: #F0F0F0
    height: 250px
    img
        height: 100%
        width: 100%
        object-fit: contain
    &__title
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 600
        line-height: 21px
        letter-spacing: 0em
        text-align: center
    &__subTitle
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: center
    &__margin
        margin: 0 auto
        padding-top: 66px
        width: 209px
.title
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 600
    line-height: 21px
    letter-spacing: 0em
    text-align: left
.inputs
    padding: 0 15px
    >div
        input
            background: #F8F8F8
            border: 1px solid rgba(0, 0, 0, 0.2)
            border-radius: 5px
            width: 100%
            height: 105px
        &:first-child
            input
                height: 30px

.questions
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 400
    line-height: 21px
    letter-spacing: 0em
    text-align: left
    padding: 15px
    >div
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
        padding-bottom: 14px
        margin-bottom: 14px
        display: flex
        align-items: center
        svg
            margin-right: 5px
.createBtn
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 10px
    width: 73px
    height: 44px
    color: #fff
    background: #3376A3
    border-radius: 5px
    &:hover
        cursor: pointer
            // background: #3376A3
    &:disabled
        opacity: 0.3
        color: #fff
.type2
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: center
    background: #F8F8F8
    border: 1px solid #3376A3
    border-radius: 10px
    color: #3376A3
    padding: 10px 20px
    &:hover
        cursor: pointer
        &:disabled
            opacity: 0.3
            color: #fff
.type1
    font-family: Zeitung Micro Pro
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: center
    background: #3376A3
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: 10px
    color: #fff
    padding: 10px 20px
    &:hover
        cursor: pointer
            // background: #3376A3
        &:disabled
            opacity: 0.3
            color: #fff
