.overlay
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    background: rgba(0, 0, 0, 0.5)
    z-index: 999999
.wrapper
    display: flex
    >div
        padding: 30px 20px
        min-width: 200px
        max-width: 400px
        // min-height: 100px
        background: #fff
        border-radius: 10px
        display: flex
        position: relative
        flex-direction: column
        align-items: center
        >span
            margin-bottom: 15px
            margin-top: 15px
        >button
            &:last-child
                background: #3376A3
                color: #fff
                border-radius: 10px
                border: none
                padding: 10px 20px
                display: flex
                align-items: center
                font-family: Zeitung Micro Pro
                &:hover
                    cursor: pointer
