.wrapper
    display: flex
    justify-content: center
    height: 100%
.members
    padding: 20px
    width: 620px
    display: flex
    flex-direction: column
    &__header
        color: #000
        font-size: 14px
        font-family: Zeitung Micro Pro
        font-style: normal
        font-weight: 700
        line-height: normal
        margin-bottom: 30px
    &__projects
        overflow: scroll
        // height: calc( 100vh - 140px )
        padding-bottom: 20px
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
        &__item
            border-bottom: 1px solid rgb(51, 118, 163,30%)
            padding: 10px 0 10px 10px
            display: flex
            align-items: center
            justify-content: space-between
            span
                color: #000
                font-size: 12px
                font-family: Zeitung Micro Pro
                font-style: normal
                font-weight: 700
                line-height: normal
            svg
                &:hover
                    cursor: pointer

            >div
                display: flex
                align-items: center
        &__thumb
            >img
                width: 50px
                height: 30px
                border-radius: 5px
                margin-right: 9px
                object-fit: cover
            >svg
                margin-right: 19px
