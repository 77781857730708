.general::-webkit-scrollbar
    display: none
.general
    display: flex
    flex-direction: column
    padding: 20px
    overflow: scroll
    height: calc( 100vh - 90px )
    -ms-overflow-style: none
    scrollbar-width: none
    &__title
        font-family: Zeitung Micro Pro
        color: red
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        color: #000
    &__subtitle
        font-size: 12px
        font-weight: 400
        letter-spacing: 0em
        text-align: left
        color: #000
        opacity: 0.5

    &__btns
        display: flex
        margin-top: 20px
        button
            border: none
            border-radius: 5px
            font-family: 'Zeitung Micro Pro'
            font-style: normal
            font-weight: 400
            font-size: 16px

    &__save
        // width: 127px
        height: 40px
        background: #3376A3
        color: #fff
        &:hover
            cursor: pointer
        &:disabled
            opacity: 0.3
            cursor: auto
    &__cancel
        width: 73px
        height: 40px
        margin-left: 15px
        font-family: 'Zeitung Micro Pro'
        font-style: normal
        // font-weight: 400
        font-size: 16px
        &:hover
            cursor: pointer
    &__delete
        color: red
        margin-top: 20px
        span
            &:hover
                cursor: pointer

.avatar
    display: flex
    align-items: center
    margin: 19px 0 32px
    >button
        width: 128px
        height: 40px
        margin-left: 30px
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-weight: 400
        line-height: 24px
        letter-spacing: 0em
        text-align: left
        border: none
        border-radius: 5px
        &:hover
            cursor: pointer
        &:disabled
            opacity: 0.3
.fields
    display: flex
    flex-direction: column
    span
        font-family: Zeitung Micro Pro
        font-size: 12px
        font-weight: 400
        line-height: 18px
        letter-spacing: 0em
        text-align: left
    input
        width: 500px
        background: #F8F8F8
        border: 1px solid rgba(0, 0, 0, 0.2)
        border-radius: 5px
        height: 36px
        margin-bottom: 27px
        margin-top: 10px
        font-family: Zeitung Micro Pro
        font-size: 14px
        font-weight: 400
        line-height: 21px
        letter-spacing: 0em
        text-align: left
        padding-left: 10px
        &:focus
            border: 2px solid #3376A3
// .members
//     padding: 20px
//     &__header
//         color: #000
//         font-size: 14px
//         font-family: Zeitung Micro Pro
//         font-style: normal
//         font-weight: 700
//         line-height: normal
//         margin-bottom: 30px
//     &__projects
//         overflow: scroll
//         height: calc( 100vh - 140px )
//         padding-bottom: 20px
//         -ms-overflow-style: none
//         scrollbar-width: none
//         &::-webkit-scrollbar
//             display: none
//         &__item
//             border-bottom: 1px solid rgb(51, 118, 163,30%)
//             padding: 10px 0 10px 10px
//             display: flex
//             align-items: center
//             justify-content: space-between
//             span
//                 color: #000
//                 font-size: 12px
//                 font-family: Zeitung Micro Pro
//                 font-style: normal
//                 font-weight: 700
//                 line-height: normal
//             svg
//                 &:hover
//                     cursor: pointer

//             >div
//                 display: flex
//                 align-items: center
//         &__thumb
//             >img
//                 width: 50px
//                 height: 30px
//                 border-radius: 5px
//                 margin-right: 9px
//                 object-fit: cover
//             >svg
//                 margin-right: 19px
.plans
    display: flex
    flex-direction: column
    padding: 20px
    font-family: Zeitung Micro Pro
    font-size: 14px
    font-weight: 600
    height: 100%
    text-align: center
    &__title
        font-size: 14px
        font-weight: 600
        text-align: left

    &__container
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100%

    &__item
        display: flex
        flex-direction: column
        padding: 50px 30px 39px
        background: #fff
        border-radius: 10px
        margin-right: 17px
        align-items: center
        &:last-child
            padding: 43px 30px 59px
    &__active

    &__activeItem
        display: flex
        color: #8F8A8A
        font-size: 14px
        font-weight: 400
        align-items: center
        svg
            margin-right: 10px
    &__btn
        margin-top: 52px
        width: 100%
        button
            width: 100%
            border-radius: 10px
            height: 50px
            background: #3376A3
            color: #fff
            border: none
            color: #FFF
            text-align: center
            font-family: Zeitung Micro Pro
            font-size: 14px
            font-style: normal
            font-weight: 600
            line-height: normal
            text-transform: capitalize
            &:disabled
                opacity: 0.6
            &:hover
                cursor: pointer
    &__price
        text-align: center
        font-family: Zeitung Micro Pro
        font-size: 16px
        font-style: normal
        font-weight: 400
        height: 54px
        span
            font-size: 13px
            color: #8F8A8A
    &__recomended
        color: #FFF
        // font-family: Zeitung Micro Pro
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: normal
        background: #3376A3
        border-radius: 10px
        width: 88px
        margin-bottom: 10px
        display: flex
        justify-content: center
.about
    display: flex
    flex-direction: column
    padding: 20px
    img
        width: 100%
        margin-bottom: 15px
    >div
        display: flex
        text-align: left