.overlay
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    background: rgba(0, 0, 0, 0.5)
    z-index: 999999
.wrapper
    display: flex
