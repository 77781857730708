
.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.main-container{
  background-color: #F8F8F8;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc( 100vh - 50px );
}
.flex-column{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flex-row{
  /* display: flex; */
}
.wrapper{
  display: flex
}
    
/* .MuiIcon-root{
  color:red
} */
.Mui-selected {
  color: #4B4B4B !important;
}

.deck-wrapper {
  display: flex;
  justify-content: space-between;
}